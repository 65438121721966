<template>
    <div>
        <form-element-group threecols>
            <template v-slot:col-1>
                <form-text-field
                    ref="firstname"
                    :placeholder="$t('applications.enter-first-name')"
                    required
                    icon="person"
                    :rules="[
                        (v) => !!v || $t('applications.first-name-required'),
                    ]"
                    :label="$t('applications.first-name')"
                    name="first_name"
                    :value="model.first_name"
                    @blur="update('first_name', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    ref="lastname"
                    :placeholder="$t('applications.enter-last-name')"
                    required
                    icon="person"
                    :value="model.last_name"
                    :rules="[
                        (v) => !!v || $t('applications.last-name-required'),
                    ]"
                    name="last_name"
                    :label="$t('applications.last-name')"
                    @blur="update('last_name', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-3>
                <form-text-field
                    ref="birthdate"
                    :label="$t('applications.date-of-birth')"
                    required
                    placeholder="mm/dd/yyyy"
                    mask="##/##/####"
                    return-masked-value
                    icon="calendar_today"
                    name="birthdate"
                    v-model="model.birthdate"
                    :rules="[
                        (v) => !!v || $t('applications.date-of-birth-required'),
                        (v) =>
                            /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                v
                            ) || $t('applications.date-of-birth-invalid'),
                    ]"
                    @blur="update('birthdate', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>
<script>
export default {
    name: "applicant-dependent",
    components: {},
    props: {
        modelData: {
            type: Object,
            required: true,
            validator: (value) => {
                const params = Object.keys(value);
                return ["first_name", "last_name", "birthdate"].every((elem) =>
                    params.includes(elem)
                );
            },
        },
    },
    data() {
        return {
            model: this.modelData,
        };
    },
    methods: {
        update(key, data) {
            this.model[key] = data;
            this.$emit("update", this.model);
        },
    },
    mounted() {},
    created() {},
    updated() {},
};
</script>

<style scoped>
</style>
